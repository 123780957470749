/** @jsxRuntime classic */
// Always keep polyfills on top of this file!
import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"

import React from "react"
import ReactDOM from "react-dom"
import Modal from "react-modal"

import "intl"
import "intl/locale-data/jsonp/de.js"

import "assets/styles/typography.css"
import { loadIntlPolyfills } from "config/intlPolyfills"

async function render() {
  const { default: App } = await import("./App")
  ReactDOM.render(<App />, document.getElementById("root"))
}

Modal.setAppElement("#root")

loadIntlPolyfills().then(render)

// import registerServiceWorker from './registerServiceWorker'
// registerServiceWorker()
